.Header {
    @apply my-10 text-gray-800 flex flex-row items-center sticky top-0 bg-slate-100 z-10;
}

.Header .Content {
    max-width: 960px;
    @apply mx-auto w-full flex flex-row px-4 h-full items-center;
}

.Header .Title {
    @apply flex-grow;
}