@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    height: 100vh;
    overflow-y: auto;
    @apply bg-slate-100;
    scroll-behavior: smooth;
}

html {
    min-height: 100vh;
    scroll-behavior: smooth;
  }

#categories {
	scroll-behavior: smooth;
}

#root {
    @apply h-full flex flex-col;
}