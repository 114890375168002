.Hero .dashed-grid-paper {
  --grid-size: 15px;
  --grid-strength: 1px;
  --grid-dash: 3px;
  --grid-gap: 2px;
  --grid-color: #ddd;
  --paper-color: #fff;

  background-color: var(--paper-color);
  background-size: var(--grid-dash) var(--grid-dash),
    var(--grid-size) var(--grid-size);
  background-image: linear-gradient(
      to bottom,
      transparent var(--grid-gap),
      var(--paper-color) var(--grid-gap)
    ),
    linear-gradient(
      to right,
      var(--grid-color) var(--grid-strength),
      transparent var(--grid-strength)
    ),
    linear-gradient(
      to right,
      transparent var(--grid-gap),
      var(--paper-color) var(--grid-gap)
    ),
    linear-gradient(
      to bottom,
      var(--grid-color) var(--grid-strength),
      transparent var(--grid-strength)
    );
}
